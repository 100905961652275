import { useMyselfStore } from '@/stores/myself'
import { useOrganizationStore } from '@/stores/organization'
import { Action, CRUDL, Subject } from '@/types/acl'
import { OrganizationMemberPermission, PartnerMemberPermission } from '@/types/user'
import { AbilityBuilder, createMongoAbility } from '@casl/ability'

export const getAbility = (isInit?: boolean) => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility)

  /**
   *  ██████  ██████   ██████   █████  ███    ██ ██ ███████  █████  ████████ ██  ██████  ███    ██
   * ██    ██ ██   ██ ██       ██   ██ ████   ██ ██    ███  ██   ██    ██    ██ ██    ██ ████   ██
   * ██    ██ ██████  ██   ███ ███████ ██ ██  ██ ██   ███   ███████    ██    ██ ██    ██ ██ ██  ██
   * ██    ██ ██   ██ ██    ██ ██   ██ ██  ██ ██ ██  ███    ██   ██    ██    ██ ██    ██ ██  ██ ██
   *  ██████  ██   ██  ██████  ██   ██ ██   ████ ██ ███████ ██   ██    ██    ██  ██████  ██   ████
   */

  const organizationViewerAbilities = () => {
    can(Action.READ, Subject.DASHBOARD)
    can(Action.READ, [Subject.ACCOUNT, Subject.ACCOUNT_GENERAL, Subject.ACCOUNT_SECURITY])
    can([Action.READ, Action.LIST, Action.EXPORT], Subject.CAMPAIGN)
    can(Action.LIST, Subject.SCENARIO)
  }

  const organizationAdminAbilities = () => {
    can(Action.READ, Subject.ADVANCED_SIMULATIONS)
    can(Action.READ, Subject.SMISHING)
    can(Action.READ, Subject.VISHING)
    can(Action.READ, Subject.BESPOKE_ATTACKS)
    can(Action.READ, Subject.DASHBOARD)
    can(Action.READ, Subject.CYBER_THREAT_INTELLIGENCE)

    can(Action.READ, Subject.DASHBOARD_STATISTICS)

    // ORGANIZATION
    can(Action.READ, Subject.ORGANIZATION_GENERAL)
    can(Action.LIST, Subject.ORGANIZATION_TEAM)
    can(Action.LIST, Subject.ORGANIZATION_DOMAIN)
    can(Action.LIST, Subject.ORGANIZATION_INTEGRATIONS)
    can(Action.READ, Subject.ORGANIZATION_INTEGRATIONS)
    can(Action.READ, Subject.ORGANIZATION_SUBSCRIPTION)
    can(Action.READ, Subject.ORGANIZATION_CAMPAIGNS_SETTINGS)
    can([Action.LIST, Action.CREATE], Subject.ORGANIZATION_PHISHING_DOMAIN)

    can(CRUDL, Subject.SETTING)

    // EMPLOYEES & GROUPS
    can(CRUDL, Subject.EMPLOYEE)
    can(CRUDL, Subject.GROUP)

    // CAMPAIGNS
    can([Action.CREATE, Action.DELETE, Action.UPDATE], Subject.CAMPAIGN)
    can([Action.DELETE], Subject.EVENT)

    // SCENARIOS
    can([...CRUDL, Action.DUPLICATE], Subject.SCENARIO)
    can(CRUDL, Subject.AI_SCENARIO)
    can(Action.CREATE, Subject.SCENARIO_RECOMMENDATION)

    can(Action.READ, Subject.QUOTA)

    // TRAINING AWARENESS
    can(Action.READ, Subject.TRAINING_AWARENESS)
    can(CRUDL, Subject.TRAINING_AWARENESS_LESSON)

    if (orgStore.isTrial) {
      can(Action.READ, Subject.PRICING)
      cannot(CRUDL, Subject.AI_SCENARIO)

      if (orgStore.isTrialExpired) {
        cannot([Action.CREATE, Action.UPDATE], Subject.CAMPAIGN)
        cannot([Action.CREATE, Action.UPDATE, Action.DUPLICATE], Subject.SCENARIO)
      }
    }

    if (!orgStore.organization?.abilities.enableTrainingAwareness) {
      cannot(CRUDL, Subject.TRAINING_AWARENESS_LESSON)
      cannot(Action.READ, Subject.TRAINING_AWARENESS)
    }

    // CYBER THREAT INTELLIGENCE
    if (orgStore.organization?.modules.cyberThreatIntelligence.canEnable) {
      can(CRUDL, Subject.CYBER_THREAT_INTELLIGENCE)
    }
  }

  const organizationOwnerAbilities = () => {
    // Team management
    can(Action.MANAGE, Subject.ORGANIZATION_TEAM_OWNER)
    // 2FA enforcement
    can(Action.UPDATE, Subject.ORGANIZATION_SECURITY)
  }

  const organizationSharedAbilities = () => {
    if (belongToPartner) {
      can(Action.ACCESS, Subject.PARTNER_ACCOUNT)
    }
  }

  /**
   *  ██████   █████  ██████  ████████ ███    ██ ███████ ██████
   *  ██   ██ ██   ██ ██   ██    ██    ████   ██ ██      ██   ██
   *  ██████  ███████ ██████     ██    ██ ██  ██ █████   ██████
   *  ██      ██   ██ ██   ██    ██    ██  ██ ██ ██      ██   ██
   *  ██      ██   ██ ██   ██    ██    ██   ████ ███████ ██   ██
   */
  const partnerOwnerAbilities = () => {
    can([Action.LIST], Subject.PARTNER_ORGANIZATIONS)
    can([Action.DELETE], Subject.PARTNER_ORGANIZATIONS)
    can([Action.READ, Action.UPDATE], Subject.PARTNER_SETTINGS)
    can([Action.MANAGE], Subject.PARTNER_TEAM_OWNER)
    can([Action.MANAGE], Subject.PARTNER_BILLING)
    can([Action.UPDATE], Subject.PARTNER_SECURITY)
  }

  const partnerAdminAbilities = () => {
    can([Action.LIST], Subject.PARTNER_ORGANIZATIONS)
    can([Action.READ, Action.UPDATE], Subject.PARTNER_SETTINGS)
  }

  /**
   * ████████ ███████ ███    ██  █████  ███    ██ ████████ ██      ███████ ███████ ███████
   *    ██    ██      ████   ██ ██   ██ ████   ██    ██    ██      ██      ██      ██
   *    ██    █████   ██ ██  ██ ███████ ██ ██  ██    ██    ██      █████   ███████ ███████
   *    ██    ██      ██  ██ ██ ██   ██ ██  ██ ██    ██    ██      ██           ██      ██
   *    ██    ███████ ██   ████ ██   ██ ██   ████    ██    ███████ ███████ ███████ ███████
   */
  const tenantLessAbilities = () => {
    can(Action.LIST, Subject.ORGANIZATIONS)
    can(Action.READ, Subject.DASHBOARD)
  }

  // Anyone can...
  can(Action.READ, [Subject.ERROR, Subject.AUTH, Subject.DISPATCHER, Subject.MFA_ENROLL])

  if (isInit) return build()

  const {
    isTenantLess,
    isLoggedInAsPartner,
    isLoggedInAsOrganization,
    getTenantRole,
    belongToPartner,
  } = useMyselfStore()
  const orgStore = useOrganizationStore()

  if (isTenantLess) {
    tenantLessAbilities()
    return build()
  }

  if (isLoggedInAsPartner) {
    if (getTenantRole === PartnerMemberPermission.OWNER) {
      partnerOwnerAbilities()
    }

    if (getTenantRole === PartnerMemberPermission.ADMIN) {
      partnerAdminAbilities()
    }
  }

  if (isLoggedInAsOrganization) {
    organizationSharedAbilities()

    if (getTenantRole === OrganizationMemberPermission.VIEWER) {
      organizationViewerAbilities()
    }

    if (getTenantRole === OrganizationMemberPermission.ADMIN) {
      organizationViewerAbilities()
      organizationAdminAbilities()
    }

    if (getTenantRole === OrganizationMemberPermission.OWNER) {
      organizationViewerAbilities()
      organizationAdminAbilities()
      organizationOwnerAbilities()
    }
  }

  return build()
}
