import { storage } from '@/services/firebase'
import { Organization, organizationSchema } from '@/types/organization'
import { GoogleIdentityDirectoryGroups } from '@/types/organization/integrations.google-identity-directory'
import { MicrosoftIdentityDirectoryGroups } from '@/types/organization/integrations.microsoft-identity-directory'
import { OrganizationStats, organizationStatsSchema } from '@/types/organization/stats'
import { apiFetch } from '@/utils/api'
import { throwApiError, validateObject } from '@/utils/api-data'
import { ref, uploadBytes } from 'firebase/storage'
import {
  Integration,
  MicrosoftPhishReportArsenAddInDetails,
  integrationsSchema,
} from './../types/organization/integrations'

export const fetchOrganization = async (): Promise<Organization> => {
  try {
    const res = await apiFetch<Organization>('/read-organization')

    return validateObject(organizationSchema, res)
  } catch (error) {
    return throwApiError(error)
  }
}

export const fetchOrganizationStats = async () => {
  try {
    const res = await apiFetch<OrganizationStats>('/read-organization-statistics')

    return validateObject(organizationStatsSchema, res)
  } catch (error) {
    return throwApiError(error)
  }
}

export const updateOrganizationName = async (name: string) => {
  try {
    await apiFetch<{ organization: Organization }>('/update-organization-name', {
      method: 'POST',
      body: {
        name,
      },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const uploadLogo = async (logo: Blob, orgId: string) => {
  const logoRef = ref(storage, `organizations/${orgId}/account/logo.png`)

  return uploadBytes(logoRef, logo)
}

export const setAutoTerminationDuration = async (payload: { autoTerminationDuration: number }) => {
  try {
    await apiFetch('/update-organization-campaigns-auto-termination-duration', {
      method: 'POST',
      body: {
        autoTerminationDuration: payload.autoTerminationDuration,
      },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const updateOrganizationMfaEnforcement = async (isEnforced: boolean) => {
  try {
    return await apiFetch('/update-mfa-enforcement', { method: 'POST', body: { isEnforced } })
  } catch (error) {
    return throwApiError(error)
  }
}

export const updateOrganizationSsoEnforcement = async (isEnforced: boolean) => {
  try {
    return await apiFetch('/update-sso-enforcement', { method: 'POST', body: { isEnforced } })
  } catch (error) {
    return throwApiError(error)
  }
}

export const verifyOrganizationDetails = async () => {
  try {
    await apiFetch('/verify-organization-details', { method: 'POST' })
  } catch (error) {
    return throwApiError(error)
  }
}

// <BEGIN INTEGRATIONS>
export const fetchIntegrations = async () => {
  try {
    const { integrations } = await apiFetch<{ integrations: Integration[] }>('/list-integrations', {
      method: 'GET',
    })

    return validateObject(integrationsSchema, integrations)
  } catch (error) {
    return throwApiError(error)
  }
}

export const downloadMicrosoftPhishReportArsenAddInManifest = async (
  data: MicrosoftPhishReportArsenAddInDetails,
) => {
  try {
    const { manifest } = await apiFetch<{ manifest: string }>(
      '/integrations/microsoft-phish-report-arsen-addin/download-manifest',
      { method: 'POST', body: data },
    )
    const link = document.createElement('a')

    link.href = window.URL.createObjectURL(new Blob([manifest], { type: 'text/xml' }))
    link.setAttribute('download', `manifest.${Date.now()}.xml`)
    document.body.append(link)
    link.click()
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const installMicrosoftPhishReportArsenAddInIntegration = async () => {
  try {
    await apiFetch('/integrations/microsoft-phish-report-arsen-addin/install', {
      method: 'POST',
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const uninstallMicrosoftPhishReportArsenAddInIntegration = async () => {
  try {
    await apiFetch('/integrations/microsoft-phish-report-arsen-addin/uninstall', {
      method: 'POST',
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const installMicrosoftPhishReportBuiltInButtonIntegration = async () => {
  try {
    await apiFetch('/integrations/microsoft-phish-report-builtin-button/install', {
      method: 'POST',
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const uninstallMicrosoftPhishReportBuiltInButtonIntegration = async () => {
  try {
    await apiFetch('/integrations/microsoft-phish-report-builtin-button/uninstall', {
      method: 'POST',
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const installGooglePhishReportArsenAddOnIntegration = async () => {
  try {
    await apiFetch('/integrations/google-phish-report-arsen-addon/install', {
      method: 'POST',
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const uninstallGooglePhishReportArsenAddOnIntegration = async () => {
  try {
    await apiFetch('/integrations/google-phish-report-arsen-addon/uninstall', {
      method: 'POST',
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const uninstallGoogleEmailDeliveryIntegration = async () => {
  try {
    await apiFetch('/integrations/google-email-delivery/uninstall', {
      method: 'POST',
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const installGoogleEmailDelivery = async (
  email: string,
): Promise<{
  isSuccess: boolean
  error: null | { code: string; description: string }
}> => {
  try {
    return await apiFetch('/integrations/google-email-delivery/install', {
      method: 'POST',
      body: { email },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const fetchMicrosoftEmailDeliveryIntegrationAuthorizationRequestUrl = async (
  callback: string,
): Promise<string> => {
  try {
    const { authorizationRequestUrl } = await apiFetch(
      `/integrations/microsoft-email-delivery/init?redirectUri=${callback}`,
      {
        method: 'GET',
      },
    )

    return authorizationRequestUrl
  } catch (error) {
    return throwApiError(error)
  }
}

export const uninstallMicrosoftEmailDeliveryIntegration = async () => {
  try {
    await apiFetch('/integrations/microsoft-email-delivery/uninstall', {
      method: 'POST',
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const fetchMicrosoftIdentityDirectoryIntegrationAuthorizationRequestUrl = async (
  callback: string,
): Promise<string> => {
  try {
    const { authorizationRequestUrl } = await apiFetch(
      `/integrations/microsoft-identity-directory/init?redirectUri=${callback}`,
      {
        method: 'GET',
      },
    )

    return authorizationRequestUrl
  } catch (error) {
    return throwApiError(error)
  }
}

export const installGoogleIdentityDirectory = async (): Promise<{
  isSuccess: boolean
  error: { code: string; description: string } | null
}> => {
  try {
    return await apiFetch('/integrations/google-identity-directory/install', {
      method: 'POST',
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const syncGoogleIdentityDirectory = async (): Promise<void> => {
  try {
    await apiFetch('/integrations/google-identity-directory/sync', {
      method: 'POST',
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const syncMicrosoftIdentityDirectory = async (): Promise<void> => {
  try {
    await apiFetch('/integrations/microsoft-identity-directory/sync', {
      method: 'POST',
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const fetchGoogleIdentityDirectoryGroups = async () => {
  try {
    const { groups } = await apiFetch<{ groups: GoogleIdentityDirectoryGroups }>(
      `/integrations/google-identity-directory/list-groups`,
      {
        method: 'GET',
      },
    )

    return groups
  } catch (error: any) {
    // Occurs when the user has disconnected the integration in its side, but not uninstalled in
    // our side yet.
    // Error 500 is about the integration not being reachable.
    // Avoid display a "deep tech" error waiting for the integration to be uninstalled.
    if (error.data?.statusCode === 500 || error.statusCode === 500) {
      return []
    }

    return throwApiError(error)
  }
}

export const fetchMicrosoftIdentityDirectoryGroups = async () => {
  try {
    const { groups } = await apiFetch<{ groups: MicrosoftIdentityDirectoryGroups }>(
      `/integrations/microsoft-identity-directory/list-groups`,
      {
        method: 'GET',
      },
    )

    return groups
  } catch (error: any) {
    // Occurs when the user has disconnected the integration in its side, but not uninstalled in
    // our side yet.
    // Error 500 is about the integration not being reachable.
    // Avoid display a "deep tech" error waiting for the integration to be uninstalled.
    if (error.data?.statusCode === 500 || error.statusCode === 500) {
      return []
    }

    return throwApiError(error)
  }
}

export const updateScopeSyncMicrosoftIdentityDirectory = async ({
  scope,
  groupIds,
}: {
  scope: string
  groupIds: string[]
}) => {
  try {
    await apiFetch('/integrations/microsoft-identity-directory/scope-sync', {
      method: 'POST',
      body: {
        scope,
        groupIds,
      },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const updateScopeSyncGoogleIdentityDirectory = async ({
  scope,
  groupIds,
}: {
  scope: string
  groupIds: string[]
}) => {
  try {
    await apiFetch('/integrations/google-identity-directory/scope-sync', {
      method: 'POST',
      body: {
        scope,
        groupIds,
      },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const fetchMicrosoftTeamsIntegrationAuthorizationRequestUrl = async (
  redirectUri: string,
): Promise<string> => {
  try {
    const { authorizationRequestUrl } = await apiFetch(
      `/integrations/microsoft-teams/init?redirectUri=${redirectUri}`,
      {
        method: 'GET',
      },
    )

    return authorizationRequestUrl
  } catch (error) {
    return throwApiError(error)
  }
}

export const fetchSlackIntegrationAuthorizationRequestUrl = async (
  redirectUri: string,
): Promise<string> => {
  try {
    const { authorizationRequestUrl } = await apiFetch(
      `/integrations/slack/init?redirectUri=${redirectUri}`,
      {
        method: 'GET',
      },
    )

    return authorizationRequestUrl
  } catch (error) {
    return throwApiError(error)
  }
}

// </END INTEGRATIONS>
