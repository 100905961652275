import { apiFetch } from '@/utils/api'
import { throwApiError, validateObject } from '@/utils/api-data'
import { ofetch } from 'ofetch'
import {
  Organization,
  OrganizationSender,
  ReadOrganizationStatisticsResponse,
  organizationSchema,
  readOrganizationStatisticsResponseSchema,
} from '../../domain/entities/organization'

export const organizationRepository = {
  async readOrganization(): Promise<Organization> {
    try {
      const response = await apiFetch('/training-awareness/read-organization')

      return validateObject(organizationSchema, response.organization)
    } catch (error) {
      return throwApiError(error)
    }
  },

  async readOrganizationStatistics() {
    try {
      const readOrganizationStatisticsResponse = await apiFetch<ReadOrganizationStatisticsResponse>(
        '/training-awareness/read-organization-statistics',
      )

      return validateObject(
        readOrganizationStatisticsResponseSchema,
        readOrganizationStatisticsResponse,
      ).statistics
    } catch (error) {
      return throwApiError(error)
    }
  },

  async enable(organizationName: Organization['name']): Promise<{
    isTrainingAwarenessEnabled: boolean
    isTrainingAwarenessStarted: boolean
  }> {
    try {
      const response = await apiFetch('/training-awareness/enable', {
        method: 'POST',
        body: JSON.stringify({ name: organizationName }),
      })

      const { isTrainingAwarenessEnabled, isTrainingAwarenessStarted } = response.organization

      return {
        isTrainingAwarenessEnabled,
        isTrainingAwarenessStarted,
      }
    } catch (error) {
      return throwApiError(error)
    }
  },

  async disable(): Promise<{
    isTrainingAwarenessEnabled: boolean
    isTrainingAwarenessStarted: boolean
  }> {
    try {
      const response = await apiFetch('/training-awareness/disable', {
        method: 'POST',
      })

      const { isTrainingAwarenessEnabled, isTrainingAwarenessStarted } = response.organization

      return {
        isTrainingAwarenessEnabled,
        isTrainingAwarenessStarted,
      }
    } catch (error) {
      return throwApiError(error)
    }
  },

  async start(): Promise<{
    isTrainingAwarenessEnabled: boolean
    isTrainingAwarenessStarted: boolean
  }> {
    try {
      const response = await apiFetch('/training-awareness/start', {
        method: 'POST',
      })

      const { isTrainingAwarenessEnabled, isTrainingAwarenessStarted } = response.organization

      return {
        isTrainingAwarenessEnabled,
        isTrainingAwarenessStarted,
      }
    } catch (error) {
      return throwApiError(error)
    }
  },

  async pause(): Promise<{
    isTrainingAwarenessEnabled: boolean
    isTrainingAwarenessStarted: boolean
  }> {
    try {
      const response = await apiFetch('/training-awareness/pause', {
        method: 'POST',
      })

      const { isTrainingAwarenessEnabled, isTrainingAwarenessStarted } = response.organization

      return {
        isTrainingAwarenessEnabled,
        isTrainingAwarenessStarted,
      }
    } catch (error) {
      return throwApiError(error)
    }
  },

  async sendDryRunLessonToUser(lessonName: string, language: string): Promise<void> {
    try {
      await apiFetch('/training-awareness/send-dry-run-lesson-to-user', {
        method: 'POST',
        body: JSON.stringify({ lessonName, language }),
      })
    } catch (error) {
      return throwApiError(error)
    }
  },

  async updateSender(sender: OrganizationSender | null): Promise<void> {
    try {
      await apiFetch('/training-awareness/update-sender', {
        method: 'POST',
        body: JSON.stringify({ sender }),
      })
    } catch (error) {
      return throwApiError(error)
    }
  },

  async updateLogo(file: File): Promise<void> {
    try {
      const { organization } = await apiFetch<{ organization: { uploadLogoUrl: string } }>(
        '/training-awareness/generate-upload-organization-logo-url',
        {
          method: 'GET',
        },
      )

      await ofetch(organization.uploadLogoUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Access-Control-Request-Method': 'PUT',
          'Content-Type': file.type,
        },
      })
    } catch (error) {
      return throwApiError(error)
    }
  },

  async removeLogo(): Promise<void> {
    await apiFetch('/training-awareness/remove-organization-logo', {
      method: 'DELETE',
    })
  },

  async disableMicrosoftTeamsChannel(): Promise<void> {
    await apiFetch('/training-awareness/disable-organization-microsoft-teams-lesson-channel', {
      method: 'POST',
    })
  },

  async enableMicrosoftTeamsChannel(): Promise<void> {
    await apiFetch('/training-awareness/enable-organization-microsoft-teams-lesson-channel', {
      method: 'POST',
    })
  },

  async disableSlackChannel(): Promise<void> {
    await apiFetch('/training-awareness/disable-organization-slack-lesson-channel', {
      method: 'POST',
    })
  },

  async enableSlackChannel(): Promise<void> {
    await apiFetch('/training-awareness/enable-organization-slack-lesson-channel', {
      method: 'POST',
    })
  },
}
